.gray-image {
    filter: grayscale(100%);
    width: 28px;
    height: auto;
}

.gray-image-smaller {
  filter: grayscale(100%);
  width: 25px;
  height: auto;
}

.gray-image:hover {
  filter: none;
}

.centre-align {
  margin: 0 auto;
  width: 600px; /* fixed width */ 
}
